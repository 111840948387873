export const CoursewareTypeOpts = [
  { label: 'PPT', value: 'ppt' },
  { label: '视频', value: 'video' },
  { label: '音频', value: 'audio' },
  { label: '图片', value: 'image' },
  { label: 'flash', value: 'swf' },
  { label: '说课', value: 'speak' },
  { label: '教案', value: 'plan' }
]

// table中使用
export const CoursewareTypeMap = {
  ppt: 'PPT',
  audio: '音频',
  video: '视频',
  image: '图片',
  swf: 'flash动画',
  speak: '说课',
  plan: '教案'
}

export const CoursewareStatusMap = {
  1: { label: '上架', color: '#87d068' },
  2: { label: '下架', color: '#f50' }
}

export const CoursewareStatus = {
  SHELVE: 1,
  UNSHELVE: 2
}

export const CoursewareStatusOpts = [
  { value: 1, label: '上架' },
  { value: 2, label: '下架' }
]

export const CoursewareType = {
  PPT: 'ppt',
  IMAGE: 'image',
  AUDIO: 'audio',
  VIDEO: 'video',
  FLASH: 'swf',
  SPEAK: 'speak',
  PLAN: 'plan'
}
