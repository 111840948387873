<template>
    <div class="editor-body">
      <div class="editor-content">
        <div  style="border: 1px solid #ccc; width: 100%;">
          <Editor
            style="height: 300px; overflow-y: hidden;"
            v-model="valueHtml"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="handleCreated"
          />
        </div>
        <div class="functionArea">
          <a-button type="primary" size="large" @click="handleSave" v-show="!readOnly">保存</a-button>
        </div>
      </div>
    </div>
  </template>

<script setup>
import { onBeforeUnmount, ref, shallowRef, onMounted, defineEmits, defineProps, computed } from 'vue'
import { Editor } from '@wangeditor/editor-for-vue'
import {
  getCoursewareInfo
} from '@/services/courseware'
import '@wangeditor/editor/dist/css/style.css' // 引入 css

const props = defineProps({
  readOnly: {
    type: Boolean,
    default: true
  },
  content: {
    type: Object,
    default: null
  },
  id: Number
})

const getIdValue = computed(() => props.content)
console.log(getIdValue.value)
const emits = defineEmits(['onSave'])

// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef()

// 内容 HTML
const valueHtml = ref('')

const previewValue = () => {
  valueHtml.value = getIdValue.value.plan_content
}
previewValue()

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value
  if (editor == null) return
  editor.destroy()
})

const handleSave = () => {
  emits('onSave', valueHtml.value)
}

const handleCreated = (editor) => {
  editorRef.value = editor // 记录 editor 实例，重要！
  // 设置只读
  if (props.readOnly) {
    editor.disable()
  }
}
</script>

  <style lang="less" scoped>
  .functionArea {
    padding: 10px;
  }
  .editor-body {
    position: relative;
    background-color: #fff;
    margin-top: 10px;
    padding: 20px;
    width: 100%;
    height: 100%;
    text-align: justify;
  }
  </style>
