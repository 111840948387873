export default {
  // 校端部分
  MINE_COURSEWARE: 'mine-courseware',
  MINE_COURSEWARE_SHARE_LIST: 'mine-courseware-share-list',
  COURSEWARE_SQUARE: 'courseware-square',
  SCHOOL_COURSEWARE_DATABASE: 'school-courseware-database',
  SCHOOL_COURSEWARE_AUDIT: 'school-courseware-audit',

  // 局端部分
  INS_COURSEWARE_DATABASE: 'ins-courseware-database',
  INS_COURSEWARE_AUDIT: 'ins-courseware-audit',

  // 后台部分
  PLATFORM_COURSEWARE_AUDIT: 'platform-courseware-audit'
}
