import { getRequest, postRequest } from '@/utils/http'

// 获取教师分享到学校的课件
export const getSchoolShareCoursewares = (params: any): any => getRequest('/school/coursewares', params)

// 校本课件库课件上架
export const enableTeacherCourseware = (id: string): any => postRequest(`/school/courseware/${id}/enable`)

// 校本课件库课件上架
export const disableTeacherCourseware = (id: string): any => postRequest(`/school/courseware/${id}/disable`)

// 获取学校课件库课件信息
export const getCouresewareBySchoolCoursewareDatabase = (id: string): any => getRequest(`/school/courseware/${id}`)

// 获取教师分享到区域的课件
export const getInstitutionShareCoursewares = (params: any): any => getRequest('/institution/coursewares', params)

// 区域课件库课件上架
export const enableInstitutionTeacherCourseware = (id: string): any => postRequest(`/institution/courseware/${id}/enable`)

// 区域课件库课件上架
export const disableInstitutionTeacherCourseware = (id: string): any => postRequest(`/institution/courseware/${id}/disable`)

// 获取区域课件库课件信息
export const getCouresewareByInstitutionCoursewareDatabase = (id: string): any => getRequest(`/institution/courseware/${id}`)
// 获取区域学科可选项
export const getInsSubjectEnum = (): any => getRequest('/institution/common/subject-cat-data')

// 获取区域出版社可选项
export const getInsPrevissionEnum = (): any => getRequest('/institution/common/press-version-cat-data')

// 增加区域查看日志
export const addInstitutionViewLog = (id: string) => postRequest(`/teacher/area-public-courseware/${id}/add-view-log`)
